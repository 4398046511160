import { graphql, navigate } from 'gatsby'
import React from 'react'
import { motion } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { RichText } from 'prismic-reactjs';
import style from '../styles/project.module.css';
import { animationLayouts } from '../styles/animationLayouts';
import SwiperCore, { Navigation } from 'swiper';
import goBackIcon from '../images/upcomingIcon.svg'
import { useMediaQuery } from 'react-responsive'
import Img from 'gatsby-image'

const Project = ({ data: { prismicProject }}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 860px)' })
  SwiperCore.use([Navigation]);

  const renderExhibtions = () => {
    if(!prismicProject?.data?.related_exhibitions) return null

    const filteredExhibitions = prismicProject?.data?.related_exhibitions?.filter((exhibition) => exhibition.exhibition?.document?.data?.title?.text.length > 0);
    if(!filteredExhibitions.length > 0) return null

    return (
      <div className={style.exhibitions}>
        <h3 className={style.sidebarTitle}>EXHIBITIONS</h3>
        {filteredExhibitions?.map((relatedExhibition) => {
          const date = !relatedExhibition?.exhibition?.document?.data?.start_date
            ? ""
            : new Date(relatedExhibition?.exhibition?.document?.data?.start_date).getFullYear();
          return (
            <div>
              <p>{relatedExhibition?.exhibition?.document?.data?.title?.text}, <span>{date}</span></p>
            </div>
          )
        })}
      </div>
    )
  }

  const renderLinks = () => {
    if(!prismicProject?.data?.links?.length) return null

    const filteredLinks = prismicProject?.data?.links?.filter((link) => link.link_text?.text.length > 0);
    if(!filteredLinks.length > 0) return null

    return (
      <div>
        <h3 className={style.sidebarTitle}>LINKS</h3>
        {filteredLinks?.map(({ link, link_text }) => {
          return (
            <div>
              <a target="_blank" rel="noreferrer" href={link?.url}>{link_text?.text}</a>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <motion.div className={style.outerContainer}>
      <motion.div layoutId={animationLayouts.about} className={style.dummyAbout} />
      <motion.div className={style.wrapper} layout>
        <motion.div className={style.main} layoutId={animationLayouts.leftColumn}>
          <motion.div className={style.sliderContainer} layoutId={animationLayouts.project(prismicProject.uid)}>
            {!isMobile && (
            <Swiper
              className={style.sliderSwiper}
              slidesPerView={1}
              navigation
            >
              <SwiperSlide className={style.slide}>
                <motion.img
                  className={style.img}
                  src={prismicProject?.data?.cover_image?.fluid?.src}
                  srcSet={prismicProject?.data?.cover_image.fluid?.srcSet}
                  srcSetWebp={prismicProject?.data?.cover_image?.fluid?.srcSetWebp}
                  alt={prismicProject?.data?.cover_image?.alt}
              />
              </SwiperSlide>
              {prismicProject?.data?.pictures.map(({ picture }, index) => {
                if(!picture.fluid) return null
                return (
                  <SwiperSlide key={`picture-slider-${index}`}>
                    <img
                        className={style.img}
                        alt={picture?.alt || ""}
                        src={picture?.fluid?.src}
                        srcSet={picture?.fluid?.srcSet}
                        srcSetWebp={picture?.fluid?.sercSetWebp}
                      />
                    {picture.alt ? (
                      <div className={style.captionDesktop}>
                        <p>{picture?.alt}</p>
                      </div>
                    )
                    : null}
                  </SwiperSlide>
                )
              })}
              </Swiper>
            )}

            {isMobile && (
              <div>
                <Img
                  fluid={prismicProject?.data?.cover_image?.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
                <p>{prismicProject?.data?.cover_image?.alt}</p>
              </div>

            )}
          </motion.div>

          <div className={style.description}>
            <div className={style.content}>
              <h1 className={style.title}>{prismicProject?.data?.title?.text}</h1>
              <div className={style.richText}>
                <RichText render={prismicProject?.data?.description?.raw} linkResolver={() => "/"} />
              </div>
            </div>
            <div className={style.sidebar}>
              {renderExhibtions()}
              {renderLinks()}
            </div>
          </div>
          {isMobile && prismicProject?.data?.pictures.map(({ picture, index }) => {
            return (
              <div className={style.containerImageMobile}>
                <Img
                  key={`picture-${index}`}
                  fluid={picture?.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
                {picture?.alt ? ( <p className={style.imageAltMobile}>{picture?.alt}</p> ) : null}
              </div>
            )
          })}
        </motion.div>

        <motion.div layoutId={animationLayouts.rightColumn} className={style.goBackSidebar}>
          <button className={style.goBackButton} onClick={() => navigate('/')}>
            <motion.img animate={{ opacity: 1, transition: { delay: .2 } }} className={style.goBackIcon} src={goBackIcon} alt="Go back" />
          </button>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}
export const PageQuery = graphql`
  query Project($id: String!) {
    prismicProject(id: { eq: $id}) {
      uid
      id
      data {
        title {
          text
        }
        subtitle {
          text
        }
        description {
          raw
        }
        cover_image {
          alt
          fluid {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        related_exhibitions {
          exhibition {
            document {
              ... on PrismicExhibition {
                id
                data {
                  description {
                    text
                  }
                  title {
                    text
                  }
                  start_date
                }
              }
            }
          }
        }
        links {
          link {
            type
            url
            uid
          }
          link_text {
            text
          }
        }
        pictures {
          picture {
            alt
            fluid {
              ...GatsbyPrismicImageFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default Project
